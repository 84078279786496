/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"

// components
import Hero from "../components/Hero"
import BlueTransparentBlock from "../components/page-blocks/BlueTransparentBlock"
import Footnote from "../components/page-blocks/Footnote"
import TransactionsList from "../components/TransactionsList"
import MarkdownContent from "../components/MarkdownContent"
import Title from "../components/page-blocks/Title"
//import BorrowersSponsorsBrokers from "../components/page-blocks/BorrowersSponsorsBrokers"
import ModalLink from "../components/link-modal"

const mainContainer = {
  backgroundColor: "white",
  justifyContent: "center",
  textAlign: "center",
  display: "flex",
  paddingTop: "40px",
  paddingBottom: "40px",
}

const TransactionsTemplate = ({ data }) => {
  const d = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Metadata title={d.title} />
      <BaseStyles>
        {d.hero && d.hero.isHero && (
          <Hero
            headline={d.hero.heroHeadline}
            description={d.hero.heroDescription}
          />
        )}
        <div sx={mainContainer}>
          <MarkdownContent content={d.transactionsTitle} />
        </div>

        <TransactionsList
          blockQuote={d.firstBlockQuote.firstBlockQuote}
          isExtraWide={d.firstBlockQuote.isExtraWide}
          popup={d.firstBlockQuote.popup}
          popupCopy={d.firstBlockQuote.popupCopy}
        />

        <BlueTransparentBlock
          content={d.secondBlockQuote.secondBlockQuote}
          isExtraWide={d.secondBlockQuote.isExtraWide}
        />

        {d.footnote && <Footnote text={d.footnote} />}
      </BaseStyles>
    </Layout>
  )
}

export default TransactionsTemplate

TransactionsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero {
          isHero
          heroHeadline
          heroDescription
        }        
        transactionsTitle
        firstBlockQuote {
          firstBlockQuote
          isExtraWide
          popup
          popupCopy
        }
        secondBlockQuote {
          secondBlockQuote
          isExtraWide
        }      
        footnote
      }
    }
  }
`
