/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import DismissibleExample from "./note"
import PopupNote from "./note-2"

// components
import Transaction from "./Transaction"
import BlueTransparentBlock from "./page-blocks/BlueTransparentBlock"

const TransactionsList = ({ blockQuote, popup, popupCopy }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { dataType: { eq: "transaction" } } }
      ) {
        edges {
          node {
            frontmatter {
              transactionTitle
              loanAmount
              location
              description
              image
              closingDate
              isRendered
            }
          }
        }
      }
    }
  `)

  // remove any null date values
  const nullsRemoved = []
  data.allMarkdownRemark.edges.map((transaction) => {
    if (transaction.node.frontmatter.closingDate !== null) {
      nullsRemoved.push(transaction)
    }
  })

  // order them by date and get only the 50 most recent
  const orderedByDate = nullsRemoved.sort(function (a, b) {
    return (
      new Date(b.node.frontmatter.closingDate) -
      new Date(a.node.frontmatter.closingDate)
    )
  })
  while (orderedByDate.length > 50) {
    orderedByDate.pop()
  }

  // sort by transaction value
  const sortedTransactions = orderedByDate.sort(function (a, b) {
    return b.node.frontmatter.loanAmount - a.node.frontmatter.loanAmount
  })

  return (
    <div>
      {sortedTransactions.map((t, i) => {
        const transaction = t.node.frontmatter
        return (
          <div key={i}>
            {/* put the block quote after the second transaction */}
            {i === 2 && (
              <div>

              <BlueTransparentBlock
                  content={blockQuote}
                  popup={popup}
                  popupCopy={popupCopy}
                />
              </div>
            )}

            {/* swap the text and image every other transaction */}
            {i % 2 === 0 ? (
              <Transaction transaction={transaction} />
            ) : (
              <Transaction transaction={transaction} isReversed={true} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TransactionsList

TransactionsList.propTypes = {
  blockQuote: PropTypes.string.isRequired,
  popup: PropTypes.bool,
  popupCopy: PropTypes.string,
}
TransactionsList.defaultProps = {
  popup: false,
}
