/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-new-wrappers */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx } from "theme-ui"
import ComponentContainer from "./ComponentContainer"
import MarkdownContent from "./MarkdownContent"
import { formatTransaction } from "../utilities/formatRelations"
// styles
const title = {
  fontSize: ["32px", "32px", "60px"],
  fontWeight: 100,
  lineHeight: 1.03,
}
const location = {
  fontSize: "17px",
}
const amount = {
  fontSize: "32px",
  fontWeight: 300,
  color: "primary",
}
const line = {
  marginTop: "20px",
  borderBottom: "1px",
}

// the transaction prop can either be a single string or a JSON object
//    if it is a string, it must follow this structure:
//    "{{transactionTitle}},.,.{{loanAmount}},.,.{{location}},.,.{{description}},.,.{{image}},.,.{{isRendered}}"
const Transaction = ({ isReversed, transaction }) => {
  // if it is a string then it came from the relation widget and needs to be parsed into a json object
  if (typeof transaction === "string") {
    transaction = formatTransaction(transaction)
  }
  return (
    <ComponentContainer backgroundColor="white">
      <div
        sx={{
          marginTop: "50px",
          width: "100%",
          marginBottom: "50px",
          display: "flex",
          flexDirection: [
            "column",
            "column",
            isReversed ? "row" : "row-reverse",
          ],
          justifyContent: "left",
        }}
      >
        <div sx={{ width: ["100%", "100%", "50%"] }}>
          {transaction.image && (
            <div sx={{ maxWidth: [null, null, "390px"], paddingRight: "10px" }}>
              <img
                style={{ width: "100%" }}
                src={transaction.image}
                alt={transaction.transactionTitle}
              />
            </div>
          )}
          {transaction.isRendered && <div>Rendering</div>}
        </div>
        <div sx={{ width: "20px" }} />
        <div sx={{ width: ["100%", "100%", "50%"] }}>
          <div sx={{ maxWidth: [null, null, "390px"] }}>
            <div sx={title}>{transaction.transactionTitle} </div>
            <div sx={location}>{transaction.location} </div>
            <hr sx={line} />
            <div sx={amount}>
              ${new Number(transaction.loanAmount).toFixed(1)} Million
            </div>
            <div>
              <MarkdownContent content={transaction.description} />
            </div>
          </div>
        </div>
      </div>
    </ComponentContainer>
  )
}

export default Transaction
