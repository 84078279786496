/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from "theme-ui";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';


const popBox = {
  background: "#fff",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "86px",
}
const noteBox = {
  maxWidth: "850px",
  margin: "0 auto",
  position: "relative",
  background: "#fff",
  ".btn-close": {
    boxSizing: "content-box",
    width: "1em",
    height: "1em",
    padding: "0.25em",
    color: "#000",
    background: `transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat`,
    border: "0",
    borderRadius: "0.375rem",
    opacity: ".5",
    cursor: "pointer",
    marginRight: "calc(-0.5*var(--bs-toast-padding-x))",
    marginLeft: "var(--bs-toast-padding-x)",
    position: "absolute",
    right: "44px",
    top: "26px",
  }
}
const bodyText = {
  color: "#000",
  fontSize: "12px",
  lineHeight: "20px",
  padding: "66px 50px 100px 66px",
}
const toggleButton = {
  background: "none",
  border: "0",
  fontSize: "20px",
  color: "#000",
}
const toggleButtonBlock = {
  alignItems: "center",
  margin: "0 auto",
  width: "106px",
  marginBottom: "23px",
}
function PopupNote(props) {
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(true);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);

  return (
    <div sx={popBox}>
      <Row sx={noteBox}> 
        <Col md={6} className="mb-2">

          <div>
            <Toast show={showA} onClose={toggleShowA}>
              <Toast.Header>

              </Toast.Header>
              <Toast.Body sx={bodyText}>{props.popupCopy}</Toast.Body>
            </Toast>
          </div>  
        </Col>
      </Row>
    </div>
  );
}

export default PopupNote;